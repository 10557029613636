var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-icon',[_vm._v(" "+_vm._s('mdi-filter-outline')+" ")]),_c('h3',[_vm._v(_vm._s(_vm.$t('project.filter.filter')))])],1),_c('v-row',[_c('v-card',{attrs:{"elevation":"0","tile":"","width":"100%"}},[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","tile":""}},[_c('v-select',{attrs:{"items":_vm.getFiltersItems['info.type'],"chips":"","label":_vm.$t('project.filter.type'),"multiple":"","hint":_vm.$t('project.filter.typeHint'),"persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.slice(0, 35) + (item.length > 35 ? '...' : '')))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.types.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.types),callback:function ($$v) {_vm.types=$$v},expression:"types"}})],1)],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","tile":""}},[_c('v-select',{attrs:{"items":_vm.getFiltersItems['info.channels'],"chips":"","label":_vm.$t('project.filter.channel'),"multiple":"","hint":_vm.$t('project.filter.channelHint'),"persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.slice(0, 35) + (item.length > 35 ? '...' : '')))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.channels.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.channels),callback:function ($$v) {_vm.channels=$$v},expression:"channels"}})],1)],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","tile":""}},[_c('v-select',{attrs:{"items":_vm.getFiltersItems['metadata.dc.subject'] ? _vm.getFiltersItems['metadata.dc.subject'].filter(function (item) { return item.toLowerCase().includes(_vm.keywordsSearch.toLowerCase()); }) : [],"chips":"","label":_vm.$t('project.filter.keyword'),"multiple":"","hint":_vm.$t('project.filter.keywordHint'),"persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.slice(0, 35) + (item.length > 35 ? '...' : '')))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.keywords.length - 1)+" others) ")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-text-field',{staticClass:"ma-0 pa-2 pt-3 pb-0",staticStyle:{"border-bottom":"1px solid grey","position":"sticky","top":"0px","background":"white","z-index":"9"},attrs:{"menu-props":{ bottom: true, 'offset-y': true },"label":_vm.$t('project.search')},model:{value:(_vm.keywordsSearch),callback:function ($$v) {_vm.keywordsSearch=$$v},expression:"keywordsSearch"}})]},proxy:true}]),model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","tile":""}},[_c('v-select',{attrs:{"items":_vm.getFiltersItems['metadata.dc.rights.value'],"chips":"","label":_vm.$t('project.filter.rights'),"multiple":"","hint":_vm.$t('project.filter.rightsHint'),"persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.slice(0, 35) + (item.length > 35 ? '...' : '')))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.rights.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.rights),callback:function ($$v) {_vm.rights=$$v},expression:"rights"}})],1)],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","tile":""}},[_c('v-select',{attrs:{"items":_vm.getFiltersItems['metadata.dc.creator'],"chips":"","label":_vm.$t('project.filter.creator'),"multiple":"","hint":_vm.$t('project.filter.creatorHint'),"persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.slice(0, 35) + (item.length > 35 ? '...' : '')))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.creator.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.creator),callback:function ($$v) {_vm.creator=$$v},expression:"creator"}})],1)],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","tile":""}},[_c('v-select',{attrs:{"items":_vm.getFiltersItems['metadata.xmpRights.UsageTerms.value'],"chips":"","label":_vm.$t('project.filter.cpNotice'),"multiple":"","hint":_vm.$t('project.filter.cpNoticeHint'),"persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.slice(0, 35) + (item.length > 35 ? '...' : '')))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.usageTerms.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.usageTerms),callback:function ($$v) {_vm.usageTerms=$$v},expression:"usageTerms"}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"auto","sm":"12"}},[_c('v-btn',{attrs:{"tile":"","depressed":"","color":"success"},on:{"click":_vm.applyFilter}},[_c('v-icon',[_vm._v(" "+_vm._s('mdi-check')+" ")]),_vm._v(" "+_vm._s(_vm.$t('project.filter.apply'))+" ")],1)],1),_c('v-col',{attrs:{"md":"auto","sm":"12"}},[_c('save-filter-pop-up',{staticStyle:{"padding":"8px"},attrs:{"payload":{
              filters: {
              keywords: _vm.keywords,
              channel: _vm.channels,
              type: _vm.types,
              creator: _vm.creator,
              rights: _vm.rights,
              usageTerms: _vm.usageTerms
              },
              projectId: _vm.id
            }}})],1),_c('v-col',{attrs:{"md":"auto","sm":"12"}},[_c('v-btn',{attrs:{"tile":"","depressed":"","color":"error"},on:{"click":_vm.resetFilter}},[_c('v-icon',[_vm._v(" "+_vm._s('mdi-undo-variant')+" ")]),_vm._v(" "+_vm._s(_vm.$t('project.filter.reset'))+" ")],1)],1)],1),_c('v-card-actions',{staticClass:"pa-3"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }