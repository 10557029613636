<template>
  <div>
    <v-row>
      <v-icon>
        {{ 'mdi-filter-outline' }}
      </v-icon>
      <h3>{{ $t('project.filter.filter') }}</h3>
    </v-row>

    <v-row>
      <v-card
          elevation="0"
          tile
          width="100%"
      >
        <v-container fluid class="px-0">
          <v-row no-gutters>
            <v-col
                md="4"
                sm="12"
            >
              <!-- TYPE -->
              <v-card
                  class="pa-2"
                  outlined
                  tile
              >

                <v-select
                    v-model="types"
                    :items="getFiltersItems['info.type']"
                    chips
                    :label="$t('project.filter.type')"
                    multiple
                    :hint="$t('project.filter.typeHint')"
                    persistent-hint
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.slice(0, 35) + (item.length > 35 ? '...' : '') }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                            (+{{ types.length - 1 }} others)
                         </span>
                  </template>
                </v-select>
              </v-card>
            </v-col>

            <v-col
                md="4"
                sm="12"
            >
              <!-- CHANNELS -->
              <v-card
                  class="pa-2"
                  outlined
                  tile
              >

                <v-select
                    v-model="channels"
                    :items="getFiltersItems['info.channels']"
                    chips
                    :label="$t('project.filter.channel')"
                    multiple
                    :hint="$t('project.filter.channelHint')"
                    persistent-hint
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.slice(0, 35) + (item.length > 35 ? '...' : '') }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                            (+{{ channels.length - 1 }} others)
                         </span>
                  </template>
                </v-select>
              </v-card>
            </v-col>

            <v-col
                md="4"
                sm="12"
            >
              <!-- SUBJECT -->
              <v-card
                  class="pa-2"
                  outlined
                  tile
              >

                <v-select
                    v-model="keywords"
                    :items="getFiltersItems['metadata.dc.subject'] ? getFiltersItems['metadata.dc.subject'].filter(item => item.toLowerCase().includes(keywordsSearch.toLowerCase())) : []"
                    chips
                    :label="$t('project.filter.keyword')"
                    multiple
                    :hint="$t('project.filter.keywordHint')"
                    persistent-hint
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.slice(0, 35) + (item.length > 35 ? '...' : '') }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                            (+{{ keywords.length - 1 }} others)
                         </span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-text-field class="ma-0 pa-2 pt-3 pb-0"
                                  style="border-bottom: 1px solid grey; position: sticky; top: 0px;
                                          background: white;
                                          z-index: 9"
                                  v-model="keywordsSearch"
                                  :menu-props="{ bottom: true, 'offset-y': true }"
                                  :label="$t('project.search')"
                    >
                    </v-text-field>
                  </template>
                </v-select>
              </v-card>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col
                md="4"
                sm="12"
            >
              <!-- COPYRIGHT -->
              <v-card
                  class="pa-2"
                  outlined
                  tile
              >

                <v-select
                    v-model="rights"
                    :items="getFiltersItems['metadata.dc.rights.value']"
                    chips
                    :label="$t('project.filter.rights')"
                    multiple
                    :hint="$t('project.filter.rightsHint')"
                    persistent-hint
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.slice(0, 35) + (item.length > 35 ? '...' : '') }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                            (+{{ rights.length - 1 }} others)
                         </span>
                  </template>
                </v-select>
              </v-card>
            </v-col>

            <v-col
                md="4"
                sm="12"
            >
              <!-- CREATOR -->
              <v-card
                  class="pa-2"
                  outlined
                  tile
              >

                <v-select
                    v-model="creator"
                    :items="getFiltersItems['metadata.dc.creator']"
                    chips
                    :label="$t('project.filter.creator')"
                    multiple
                    :hint="$t('project.filter.creatorHint')"
                    persistent-hint
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.slice(0, 35) + (item.length > 35 ? '...' : '') }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                            (+{{ creator.length - 1 }} others)
                         </span>
                  </template>
                </v-select>
              </v-card>
            </v-col>

            <v-col
                md="4"
                sm="12"
            >
              <!-- USAGE-TERMS -->
              <v-card
                  class="pa-2"
                  outlined
                  tile
              >

                <v-select
                    v-model="usageTerms"
                    :items="getFiltersItems['metadata.xmpRights.UsageTerms.value']"
                    chips
                    :label="$t('project.filter.cpNotice')"
                    multiple
                    :hint="$t('project.filter.cpNoticeHint')"
                    persistent-hint
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.slice(0, 35) + (item.length > 35 ? '...' : '') }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                            (+{{ usageTerms.length - 1 }} others)
                         </span>
                  </template>
                </v-select>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-row dense>
          <v-col md="auto" sm="12">
            <v-btn
                tile
                depressed
                color="success"
                @click="applyFilter"
            >
              <v-icon>
                {{ 'mdi-check' }}
              </v-icon>
              {{ $t('project.filter.apply') }}
            </v-btn>
          </v-col>
          <v-col md="auto" sm="12">
            <save-filter-pop-up style="padding: 8px"
                                :payload="{
                filters: {
                keywords,
                channel: channels,
                type: types,
                creator,
                rights,
                usageTerms
                },
                projectId: id
              }"
            ></save-filter-pop-up>
          </v-col>
          <v-col md="auto" sm="12">
            <v-btn
                tile
                depressed
                color="error"
                @click="resetFilter"
            >
              <v-icon>
                {{ 'mdi-undo-variant' }}
              </v-icon>
              {{ $t('project.filter.reset') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-card-actions class="pa-3">
        </v-card-actions>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import SaveFilterPopUp from "@/components/SaveFilterPopUp";

export default {
  name: "FileFilter",
  props: ['id', 'filter'],
  components: {SaveFilterPopUp},
  data() {
    return {
      currentPage: 1,
      search: '',
      timer: null,
      keywordsSearch: '',
      dataTableUpdateKey: 0,
    }
  },
  beforeMount() {
    // load entries with filter
    this.loadFiltersItems(this.id);

    const request = this.getCurrentRequest;
    let filters = {
      folder: request?.filter?.folder,
      "info.projectId": this.id,
      "info.marked": false,
      "info.archived": false,
      "info.deleted": false
    }

    switch (this.$router.currentRoute.name) {
      case 'Marked':
        filters["info.marked"] = true;
        filters.folder = null;
        break
      case 'Deleted':
        filters["info.deleted"] = true;
        break
      case 'Archived':
        filters["info.archived"] = true;
        break
      default:
        filters["info.marked"] = null;
    }

    this.setCurrentRequest({
      filter: filters,
      page: request.page ? request.page : 0,
      size: this.itemsPerPage,
      sortList: []
    });

    this.applyFilter();
  },
  computed: {
    ...mapGetters({
      getFilterByIndex: 'main/getFilterByIndex',
      getFiles: 'main/getFiles',
      getCurrentRequest: 'main/getCurrentRequest',
      getItemsPerPage: 'main/getItemsPerPage',
      getFiltersItems: 'main/getFiltersItems',
      getFilterKeywords: 'main/getFilterKeywords',
      getFilterChannels: 'main/getFilterChannels',
      getFilterTypes: 'main/getFilterTypes',
      isLoading: 'main/isLoading',
      columns: 'main/getColumns'
    }),
    types: {
      get() {
        return this.getFilterTypes
      },
      set(value) {
        this.setFilterTypes(value)
      }
    },
    keywords: {
      get() {
        return this.getFilterKeywords
      },
      set(value) {
        this.setFilterKeywords(value)
      }
    },
    channels: {
      get() {
        return this.getFilterChannels
      },
      set(value) {
        this.setFilterChannels(value)
      }
    },
    creator: {
      get() {
        return this.creatorFilter
      },
      set(value) {
        this.setCreatorFilter(value)
      }
    },
    rights: {
      get() {
        return this.rightsFilter
      },
      set(value) {
        this.setRightsFilter(value)
      }
    },
    usageTerms: {
      get() {
        return this.usageTermsFilter
      },
      set(value) {
        this.setUsageTermsFilter(value)
      }
    },
    ...mapState('main', ['creatorFilter', 'rightsFilter', 'usageTermsFilter']),
    items: {
      get() {
        return this.getFiles
      },
      set(value) {
        this.setFiles(value)
      }
    },
    itemsPerPage: {
      get() {
        return this.getItemsPerPage
      },
    },
  },
  methods: {
    ...mapActions({
      loadFiles: 'main/getFiles',
      loadFiltersItems: 'main/loadFiltersItems'
    }),
    ...mapMutations('main', [
      'setFiles',
      'setFilterKeywords',
      'setFilterChannels',
      'setFilterTypes',
      'setCurrentRequest',

      'setRightsFilter',
      'setCreatorFilter',
      'setUsageTermsFilter'
    ]),
    applyFilter() {
      let request = this.getCurrentRequest
      request.filter = {
        ...request.filter,
        'info.projectId': this.id,
        'info.type': this.types,
        'info.channels': this.channels,
        'metadata.dc.subject': this.keywords,
        'metadata.dc.creator': this.creator,
        'metadata.dc.rights.value': this.rights,
        'metadata.xmpRights.UsageTerms.value': this.usageTerms
      }

      this.setCurrentRequest(request)
      this.loadFiles().then();
    },
    resetFilter() {
      this.types = this.channels = this.keywords = this.creator = this.rights = this.usageTerms = []
      this.applyFilter()
    }
  }
}
</script>

<style scoped>

</style>